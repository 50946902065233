import React from 'react';
import PageNotFound from './Components/pageNotFound/PageNotFound';

class PageNotFOund extends React.Component {
    state = {  }
    render() { 
        return ( 
            <PageNotFound />
         );
    }
}
 
export default PageNotFOund;